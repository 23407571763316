<template>
  <b-row class="loading-row">
    <b-col cols="12">
      <div class="loading-text">
        <div class="text-center">
          <b-spinner class="spinner-loading" label="Text Centered" />
          <h2 class="d-inline mr-1 ml-1">{{ $t("g.loading") }}</h2>
          <h3 class="loading-des mt-1">
            {{ $t("g.Please wait while we look up this data for you") }}
          </h3>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BSpinner } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BSpinner,
  },
};
</script>

<style lang="scss" src="./_loading.scss" />
